<template lang='pug'>

ion-page
  ion-content
    swiper(
      :modules='modules'
      :keyboard='true'
      :pagination='true'
      :navigation='true'
      @swiper='onSwiperInit'
    )
      swiper-slide#slide-0
        // ion-icon(name='finger-print')
        // ion-text
          h1
            | {{ $t('ui.pages.welcome.slide1.title') }}
          p
            | {{ $t('ui.pages.welcome.slide1.subtitle') }}
        ion-text
          h1
            | WHIM
        .ion-padding
          ion-button(
            fill='outline'
            size='default'
            @click='rewindSwiper(1)'
          )
            | {{ $t('ui.actions.sign_up') }}
          ion-button(
            fill='clear'
            disabled='true'
          )
            | {{ $t('words.or') }}
          ion-button(
            fill='solid'
            size='default'
            @click='rewindSwiper(2)'
          )
            | {{ $t('ui.actions.sign_in') }}
        .ion-padding
          LocaleSelect(label='')
      // swiper-slide#slide-1
        ion-icon(name='hand-left-outline')
        ion-text
          h1
            | {{ $t('ui.pages.welcome.slide2.title') }}
          p
            | {{ $t('ui.pages.welcome.slide2.subtitle') }}
        .ion-padding
      // swiper-slide#slide-2
        ion-icon(name='happy-outline')
        ion-text
          h1
            | {{ $t('ui.pages.welcome.slide3.title') }}
          p
            | {{ $t('ui.pages.welcome.slide3.subtitle') }}
        .ion-padding
      // swiper-slide#slide-3
        ion-icon(name='people-circle-outline')
        ion-text
          h1
            | {{ $t('ui.pages.welcome.slide4.title') }}
          p
            | {{ $t('ui.pages.welcome.slide4.subtitle') }}
        .ion-padding
      swiper-slide#slide-signup
        ion-text
          h1.ion-text-capitalize
            | {{ $t('ui.actions.sign_up') }}
        AuthSignUpForm
      swiper-slide#slide-signin
        ion-text
          h1.ion-text-capitalize
            | {{ $t('ui.actions.sign_in') }}
        AuthSignInForm
        .ion-padding
          AuthSignInWithGoogleButton

</template>

<script setup>

import { onIonViewWillEnter } from '@ionic/vue'

import {
  fingerPrint,
  handLeftOutline,
  happyOutline,
  peopleCircleOutline,
} from 'ionicons/icons'
import {
  Swiper,
  SwiperSlide,
  Keyboard,
  Pagination,
  Navigation,
} from '@/deps/swiper'

import LocaleSelect from '@/components/LocaleSelect.vue'

const modules = [
  Keyboard,
  Pagination,
  Navigation,
]

addIcons({
  'finger-print': fingerPrint,
  'hand-left-outline': handLeftOutline,
  'happy-outline': happyOutline,
  'people-circle-outline': peopleCircleOutline,
})

const swiperRef = ref(undefined)

const onSwiperInit = (instance) => {
  swiperRef.value = instance
}

const rewindSwiper = (index) => {
  swiperRef.value.slideTo(index, 400, true)
}

onIonViewWillEnter(() => {
  swiperRef.value.slideTo(0, 0, true)
})

</script>

<style lang='stylus'>

.swiper-button-disabled
  display: none

</style>

<style scoped lang='stylus'>

ion-content
  --ion-background-color: var(--ion-color-light)

.swiper
  height: 100%
  // color: var(--ion-color-primary-contrast)
  // --bullet-background-active: var(--ion-color-light)
  .swiper-slide
    flex-direction: column
    justify-content: space-evenly

    ion-icon
      font-size: 600%

#slide-signup,
#slide-signin
  justify-content: flex-start

</style>
