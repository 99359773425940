import _ from 'lodash'

const initializeIntervals = (intervals) => {
  _.forIn(intervals, (interval) => {
    interval.interval = setInterval(() => {
      interval.tick.value++
    }, interval.duration)
  })
}

const clearIntervals = (intervals) => {
  _.forIn(intervals, (interval) => {
    clearInterval(interval.interval)
  })
}

export {
  initializeIntervals,
  clearIntervals,
}
