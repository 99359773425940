<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='end')
        NavTopMyProfileButton
      ion-title
        | WHIM
  ion-content
    ion-refresher(
      slot='fixed'
      @ion-refresh='doRefresh($event)'
    )
      ion-refresher-content
    ion-segment(
      v-model='segmentRef'
      @ionChange='segmentRef = $event.detail.value'
    )
      ion-segment-button(
        v-for='segment in segments'
        :key='segment.key'
        :value='segment.key'
        layout='icon-start'
      )
        ion-label
          | {{ segment.name }}
        ion-badge(
          v-show='peopleIds[segment.key].length'
        )
          | {{ peopleIds[segment.key].length }}
    EmptyState(
      v-if='peopleIds[segmentRef].length === 0'
      name='People'
    )
    SearchFilter(
      v-model='searchQuery'
      :collection='peopleIds[segmentRef]'
    )
    van-swipe-cell(
      v-for='(person, index) in people'
      :key='person.id'
    )
      ion-item(
        @click='$router.push(`/@${person?.slug}`)'
      )
        ion-label
          | {{ person?.name }}
        PersonAvatar(
          slot='start'
          :person='people[index]'
          style='width: 3rem; height: 3rem;'
        )
      template(#left)
        van-button(
          square
          type='primary'
          :text='$t("ui.actions.edit")'
        )
      template(#right)
        van-button(
          square
          type='danger'
          :text='$t("ui.actions.delete")'
          @click='askToDestroyTarget(person)'
        )

</template>

<script setup>

import _ from 'lodash'

import { getRepo } from '@/repos'
import { t } from '@/logic/i18n'
import { confirmToRemove } from '@/logic/utils/confirmations'

const segments = computed(() => {
  const result = []
  result.push({
    key: 'target_people',
    name: t('ui.pages.people.segments.target_people'),
  })
  result.push({
    key: 'friends',
    name: t('ui.pages.people.segments.friends'),
  })
  if (false) {
    result.push({
      key: 'pending_requests',
      name: t('ui.pages.people.segments.pending_requests'),
    })
  }
  return result
})

const segmentRef = ref('target_people')
const searchQuery = ref('')

const targetRepo = getRepo('targets')
const personRepo = getRepo('people')
const me = computed(() => personRepo.getMe())

const peopleIds = computed(() => {
  const collections = me.value?.collections?.ids
  return _.reduce(segments.value, (acc, segment) => {
    acc[segment.key] = collections ? collections[segment.key] || [] : []
    return acc
  }, {})
})

const people = computed(() => {
  const ids = peopleIds.value[segmentRef.value]
  let rel =
    personRepo
      .withAll()
      .where('id', ids)
  if (searchQuery.value.length > 0) {
    rel = rel.where('name', (value) => {
      if (!_.isString(value)) { return false }
      return value.toLowerCase().includes(searchQuery.value.toLowerCase())
    })
  }
  rel = rel
    .orderBy('name', 'asc')
    .get()
  return rel
})

const doRefresh = (event) => {
  personRepo.api().readMeWithData()
    .then(() => event.target.complete())
}

const doDestroyTarget = (person) => {
  const target = targetRepo
    .where('targetable_type', 'Person')
    .where('targetable_id', person.id)
    .first()
  targetRepo.api().destroyOne({ id: target.id })
    .then(() => {
      personRepo.api().readMeWithData()
    })
}

const askToDestroyTarget = (person) => {
  confirmToRemove()
    .then(() => {
      doDestroyTarget(person)
    })
}

</script>

<style scoped lang='stylus'>

.van-swipe-cell
  .van-button
    height: 100%

.segment-button-layout-icon-start ion-badge
  margin-inline: 0.5rem 0

</style>
