import { createPinia, setActivePinia } from 'pinia'
import { createORM } from 'pinia-orm'
import { createPiniaOrmAxios } from '@pinia-orm/axios'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from 'axios'

import { headers } from '@/logic/api/client/headers'
import { routes } from '@/logic/api/routes'
import { setAuthTokenOnHeaders } from '@/logic/api/client/setAuthTokenOnRequest'

const pinia = createPinia()
const piniaOrm = createORM()

const piniaOrmAxios = createPiniaOrmAxios({
  axios,
  headers,
  baseURL: routes._base,
  transformRequest: (data, headers) => {
    setAuthTokenOnHeaders(headers)
    return data
  },
})

piniaOrm()
  .use(piniaOrmAxios)

pinia
  .use(piniaOrm)
  .use(piniaPluginPersistedstate)

setActivePinia(pinia)

export {
  pinia,
}
