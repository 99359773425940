<template lang='pug'>

ion-img.fake(
  ref='fakeImage'
  v-if='url'
  :src='url'
  @ionImgDidLoad='onLoad'
)

ion-img(
  v-if='loadedUrl'
  :src='loadedUrl'
  :class="{ 'is-loaded': imageIsLoaded }"
)

</template>

<script setup>

import { PersonRepo } from '@/repos'

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
})

const fakeImage = ref(null)
const loadedUrl = ref(null)
const imageIsLoaded = ref(false)

const { id } = toRefs(props)

const personRepo = useRepo(PersonRepo)

const url = computed(() => {
  return personRepo.find(id.value)?.photo?.full_url || null
})

const updateUrl = (newUrl) => {
  imageIsLoaded.value = false

  if (!newUrl) {
    loadedUrl.value = null
    return
  }
}

const onLoad = () => {
  setTimeout(() => {
    loadedUrl.value = url.value
    imageIsLoaded.value = true
  }, 100)
}

watch(url, (newUrl) => {
  if (newUrl) { updateUrl(newUrl) }
})

</script>

<style scoped lang='stylus'>

ion-img
  height: 100%
  overflow: hidden
  border-radius: 0.5rem
  opacity: 0.75
  transition: opacity 0.2s ease-in-out

  &::part(image)
    object-fit: cover
    object-position: center

  &.is-loaded
    opacity: 1

  &.fake
    visibility: hidden
    width: 1px
    height: 1px
    position: absolute
    z-index: -1

</style>
