const share = async (params = {}) => {
  if (!navigator.share) {
    console.log('Web Share API is not supported in your browser.')
    return Promise.resolve()
  }

  try {
    await navigator.share(params)
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Share canceled by the user.')
    } else {
      console.error('Error sharing:', error)
      throw error
    }
  }
}

export {
  share,
}
