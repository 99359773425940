<template lang='pug'>

ion-list(
  v-if='collection.length !== 0'
)
  ion-item
    ion-input(
      v-model='query'
      :placeholder='$t("ui.actions.search")'
      type='text'
    )
      ion-button(
        v-if='query'
        @click='resetQuery'
        fill='clear'
        slot='end'
      )
        ion-icon(
          slot='icon-only'
          icon='close-circle-outline'
        )

</template>

<script setup>

import {
  closeCircleOutline,
} from 'ionicons/icons'

const query = defineModel({
  type: String,
  default: '',
})

const props = defineProps({
  collection: {
    type: Array,
    default: [],
  },
})

const { collection } = toRefs(props)

const resetQuery = () => {
  query.value = ''
}

addIcons({
  'close-circle-outline': closeCircleOutline,
})

</script>
