<template lang='pug'>

ion-button(
  @click='onClick'
  :disabled='!isReady'
  color='primary'
  size='default'
  fill='outline'
)
  | {{ $t('ui.actions.sign_in_with_google') }}
  ion-icon(
    name='logo-google'
    slot='start'
  )

</template>

<script setup>

import {
  logoGoogle,
} from 'ionicons/icons'

addIcons({
  'logo-google': logoGoogle,
})

import { useCodeClient } from 'vue3-google-signin'

import { auth } from '@/logic'

const router = useIonRouter()

const onClick = () => {
  login()
}

const handleOnSuccess = (response) => {
  const code = response.code
  auth.service
    .signInWithGoogle(code)
    .then(
      () => {
        router.push({path: '/'})
      }
    )
}

const handleOnError = (errorResponse) => {
  console.log('Error: ', errorResponse)
}

const { isReady, login } = useCodeClient({
  onSuccess: handleOnSuccess,
  onError: handleOnError,
  redirect_uri: 'postmessage',
})

</script>

<style scoped lang='stylus'>
</style>
